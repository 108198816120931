<!-- 免费课程 -->
<template>
  <div ref="buy_lesson" class="buy_lesson">
    <headers ref="headers" :headname="headName"></headers>
    <!-- 列表 -->
    <div class="page_cnt">
      <div v-if="count > 0" class="buy_main">
        <template v-for="(lesson,index) in lessonLists">
          <div :key="index" class="lesson_item">
            <!-- 课程缩略图 -->
            <div class="lesson_thumb">
              <img :src="lesson.thumb" alt="" @click="thumbGoLessonDetail(lesson)">
            </div>
            <!-- 课程信息 -->
            <div class="lesson_info">
              <!-- 课程名 -->
              <p class="lesson_name" @click="thumbGoLessonDetail(lesson)">{{ lesson.lesson_name }}</p>
              <!-- 学习进度 -->
              <div class="lesson_learn">
                <img src="@/assets/image/mine_learned.png" alt="">
                <span class="lesson_learn_info">
                  已学习{{ lesson.looked_lesson_long }}/{{ lesson.lesson_long }}课时
                </span>
                <span @click="thumbGoLessonDetail(lesson)">
                  <span class="lesson_listen_red">
                    {{ lesson.is_end==1?'立即购买':'免费听课' }}
                  </span>
                </span>
              </div>
              <div class="learn_bot">
                <!-- 有效期 -->
                <span class="time">
                  <span v-if="lesson.is_end==1">限时免费已结束</span>
                  <span v-if="lesson.is_end==0">有效期：{{ lesson.end_time }}</span>
                </span>
              </div>
            </div>
          </div>
        </template>
        <el-pagination v-show="count>params.limit" class="page" background :total="count" layout="prev, pager, next" :page-size="params.limit" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- else 无数据 -->
      <noData v-if="count===0" ref="noData" :msg="msg" :img="img"></noData>
    </div>
  </div>
</template>

<script>
import { myFreeLesson } from '@/api/center'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  components: { headers, noData },
  data() {
    return {
      headName: '免费课程',
      msg: '您还没有免费课程哦~',
      img: require('@/assets/image/no_data_lesson.png'),
      // 列表数据
      lessonLists: [],
      // 总数
      count: undefined,
      params: {
        type: 0,
        page: 1,
        limit: 10
      }
    }
  },
  beforeCreate() {
  },
  created() {
    this.getFreeLesson()
  },
  mounted() {
  },
  methods: {
    // 已购课程
    getFreeLesson() {
      myFreeLesson(this.params).then(res => {
        const list = res.result.list
        this.lessonLists = list
        this.count = res.result.count
      }).catch(err => {
        console.log(err)
      })
    },
    // 分页
    handleCurrentChange(val) {
      //  当前点击页数
      this.params.page = val
      this.getFreeLesson()
    },
    thumbGoLessonDetail(item) {
      // 点击缩略图去课程详情的
      window.open(decodeURIComponent(item.lesson_url + '#i=1'), '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.buy_lesson {
  width: 965px;
  min-height: 900px;
  position: relative;
  font-family: "PingFangSC-Regular";
}
.top_title {
  height: 55px;
  width: 100%;
  line-height: 20px;
  font-size: 18px;
  position: relative;
  text-align: left;
  .title_text {
    display: inline-block;
    margin-left: 9px;
    margin-top: 25px;
    width: 120px;
    height: 30px;
    line-height: 30px;
    font-family: MicrosoftYaHei;
    font-size: 18px;
    color: #444444;
  }
}
.title_border {
  float: left;
  height: 21px;
  margin-top: 30px;
  margin-left: 30px;
  width: 4px;
  background: #f04e4f;
}
.buy_main {
  position: relative;
  padding-top: 6px;
  padding-left: 30px;
  min-height: 500px;
}
.lesson_item {
  height: 160px;
  padding: 20px 0;
  border-bottom: 1px solid #dddddd;
  overflow: hidden;
  text-align: left;
}
.lesson_thumb {
  width: 160px;
  height: 120px;
  float: left;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
.lesson_info {
  float: left;
  height: 120px;
  width: 745px;
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-between;
  color: #444444;
  font-size: 16px;
  .lesson_name {
    cursor: pointer;
  }
  .lesson_learn {
    img {
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }
    .lesson_learn_info {
      color: #ee2e2e;
      font-size: 14px;
      margin-left: 10px;
      margin-right: 20px;
      width: 185px;
      display: inline-block;
    }
    .lesson_listen_red {
      display: inline-block;
      margin-left: 5px;
      width: 104px;
      height: 35px;
      background-color: #ee2e2e;
      border-radius: 4px;
      color: #fff;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
      border-radius: 16px;
    }
    .lesson_listen {
      display: inline-block;
      cursor: pointer;
      margin-left: 5px;
      width: 104px;
      height: 35px;
      background-color: #ee2e2e;
      border-radius: 4px;
      color: #fff;
      line-height: 35px;
      text-align: center;
      font-size: 14px;
    }
    .lesson_noclass {
      display: inline-block;
      width: 100px;
      height: 30px;
      margin-left: 10px;
      background-color: #fff;
      color: #999999;
      line-height: 30px;
      font-size: 14px;
    }
  }
  .learn_bot {
    display: inline-block;
    .time {
      width: 210px;
      display: inline-block;
      color: #999999;
      font-size: 14px;
      margin-right: 20px;
    }
    .bot_menus {
      display: inline-block;

      margin-left: 5px;
      .look_protocol {
        display: inline-block;
        width: 120px;
        // height: 26px;
        line-height: 24px;
        color: #ef2d2e;
        cursor: pointer;
        border-radius: 13px;
        border: solid 1px #ef2d2e;
        font-size: 14px;
        text-align: center;
        margin-right: 10px;
      }
      .reg_form {
        display: inline-block;
        width: 125px;
        height: 26px;
        cursor: pointer;
        border-radius: 13px;
        background-color: #ee2e2e;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        margin-right: 10px;
        line-height: 26px;
      }
      .look_reg_form {
        display: inline-block;
        width: 125px;
        height: 26px;
        cursor: pointer;
        border-radius: 13px;
        border: solid 1px #ef2d2e;
        font-size: 14px;
        color: #ef2d2e;
        text-align: center;
        margin-right: 10px;
        line-height: 26px;
      }
      .invoice {
        display: inline-block;
        width: 84px;
        height: 26px;
        line-height: 26px;
        cursor: pointer;
        border-radius: 13px;
        border: solid 1px #ef2d2e;
        font-size: 14px;
        color: #ef2d2e;
        text-align: center;
      }
      .agreement {
        margin-right: 10px;
        width: 125px;
      }
      .look_invoice {
        display: inline-block;
        width: 84px;
        height: 26px;
        line-height: 26px;
        cursor: pointer;
        border-radius: 13px;
        background-color: #ee2e2e;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}
.t_qrcode {
  position: absolute;
  flex-direction: column;
  display: flex;
  right: 5px;
  width: 125px;
  height: 120px;
  margin-right: 10px;
  img {
    margin-top: 20px;
    margin-left: 27px;
    width: 72px;
    height: 72px;
  }
  .code_tit {
    margin-top: 3px;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    color: #999999;
  }
}
.noDatasBox {
  position: relative;
  width: 100%;
  min-height: 500px;
  img {
    margin-top: 140px;
    width: 140px;
    height: 140px;
  }
  p {
    margin-top: 35px;
    text-align: center;
    line-height: 20px;
  }
}
.page {
  padding: 20px 0;
  margin: 0 auto;
}
.recommend_box {
  margin-top: 20px;
  padding: 20px 10px 0;
  height: 280px;
  overflow: hidden;
  .recommend_top {
    font-size: 20px;
    color: #666;
    line-height: 25px;
    img {
      vertical-align: middle;
    }
  }
  .recommend_item {
    margin-top: 20px;
    li {
      border: 1px solid #e5e5e5;
      width: 170px;
      height: 170px;
      display: block;
      float: left;
      margin-left: 12px;
      cursor: pointer;
    }
  }
  .img_box {
    width: 170px;
    height: 125px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .lesson_name {
    padding: 5px 5px 0px;
    width: 160px;
    text-align: left;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.addBtn{
  width: 136px;
  height: 34px;
  border: 1px solid rgba(238,46,46,1);
  background: #FFFFFF;
  color: #ee2e2e;
  .address_info{
    padding: 12px 0 0 15px;
  }
}
.addressBox{
  width: 640px;
  height: 92px;
  background: #FFFFFF;
  border: 1px solid rgba(223,223,223,1);
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.addressBox_red{
  width: 640px;
  height: 92px;
  background: #FFFFFF;
  border: 1px solid #ee2e2e;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.address_info{
  padding: 0 0 0 20px;
  .address_user{
    margin-bottom: 8px;
    font-weight: 600;
    color: #333;
    span{
      display: inline-block;
      background: #ee2e2e;
      color: #fff;
      padding: 1px 8px;
      font-weight: 500;
      font-size: 12px;
      margin-left: 7px;
    }
  }
  .address_deital{
    width: 437px;
    color: #333;
  }
}
.operationbox{
  padding: 0 10px 0 0;
  .operationbox_text{
    width: 56px;
    height: 20px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #EE2E2E;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 10px;
    vertical-align:middle;
    cursor: pointer;
  }
  img{
    width: 20px;
    margin-right: 10px;
    vertical-align:middle;
    cursor: pointer;
  }
}
.infoinp{
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  text-align: right;
  .inp{
    color: #ee2e2e;
  }
  .infoinp_tit{
    display: inline-block;
    width: 80px;
    margin-right: 10px;
  }
}
.total {
        margin: 0 auto;
        position: relative;
        width: 965px;
        background-color: #fff;
        padding: 0 20px 20px 20px;
    }

    .add_location {
        position: relative;
        text-align: left;
        margin-top: 20px;
        width: 100%;
        height: 30px;

        .msgSpan{
            margin-left: 15px;
        }
        .span-gray{
          color: #999999;
        }
    }

    .addLocationSpan {
        font-size: 15px;
        color: black;
        margin-left: 15px;
    }

    .add_location  {
        margin-top: 20px;
        color: black;
    }

    .btn_add {
        display: inline-block;
        line-height: 30px;
        width: 120px;
        text-align: center;
        // height: 30px;
        border: 1px solid #ef2d2e;
        border-radius: 20px;
        background: white;
        font-size: 14px;
        color: #ef2d2e;
        cursor: pointer;
    }

    .view_yiying {
        position: absolute;
        top: 0;
        left: -235px;
        width: 1200px;
        height: 980px;
        margin: 0;
        background-color: rgba(193, 193, 193, 0.2);
    }

    .div_content {
        width: 100%;
        position: relative;
        text-align: left;
    }

    .item {
        padding: 26px 8px 26px 8px;
        list-style: none;
        border: 1px solid #DDDDDD;
        margin-bottom: 10px;
    }

    .table {
      .span_address{
        width: 600px;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
      }
        width: 900px;
    }

    .item tr td:first-child {
        width: 80px;
        text-align: right;
        color: #999999;
        font-size: 14px;
    }

    .item tr td {
        color: #000000;
        font-size: 14px;
    }

    .item tr:last-child td:last-child {
        text-align: right;
    }

    .btn_default {
        border: none;
        background: #fff;
        font-size: 14px;
        color: black;
        cursor: pointer;
    }

    .set_defalut {
        margin-right: 32px;
    }

    .is_default {
        display: inline-block;
        line-height: 25px;
        width: 88px;
        height: 28px;
        text-align: center;
        border: 1px solid #ee2e2e;
        border-radius: 15px;
        color: #ee2e2e;
        margin-left: 19px;
        margin-right: 19px;
    }

    .edi {
        margin-right: 15px;
    }

    .mark {
        position: fixed;
        left: 0;
        top: 0;
        opacity: .5;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 998;
        pointer-events: none; //不能操作
    }

    .pop_add_local {
        position: absolute;
        left: 40%;
        top: 0;
        text-align: center;
        margin-left: -200px;
        width: 516px;
        height: 407px;
        // box-shadow: 0 0 32px 0 rgba(193, 193, 193, 0.72);
        box-shadow: 0 0 7px 0 rgba(193, 193, 193 , 0.72);
        padding-top: 26px;
        background: #ffffff;
        z-index: 1;
    }

    .province ul li a :hover {
        color: #ee2e2e;
    }

    .pop_add_local img {
        position: absolute;
        top: 3%;
        left: 100%;
        width: 24px;
        height: 24px;
        margin-left: -32px;
    }

    .pop_add_local p {
        font-size: 18px;
        color: #444444;
    }

    .pop_add_local li div {
        margin-top: 22px;
    }

    .pop_add_local li input {
        padding-left: 25px;
        width: 302px;
        height: 46px;
        line-height: 46px;
        background: none;
        border: none;
        outline: 1px solid #ccc; //边框不用border，用outline
    }

    .pop_add_local span {
        font-size: 14px;
        color: #999999;
    }

    button {
        outline: none;
    }

    .pop_add_local button {
        width: 90px;
        height: 30px;
        border-radius: 4px;

        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-top: 33px;
        cursor: pointer;
        color: white;
    }

    .div_hang {
        position: relative;
        background: gray;
        margin: 0 auto;
    }

    .chooseCity {
        position: absolute;
        top: 43%;
        left: 70%;
        margin-top: -150px;
        width: 390px;
        padding-bottom: 24px;
        background: #ffffff;
        padding-top: 17px;
        box-shadow: 0 0 32px 0 rgba(193, 193, 193, 0.72);
    }

    .chooseCity span {
        font-size: 15px;
        color: #444444;
    }

    .chooseCity a {
        color: #ee2e2e;
        font-size: 15px;
    }

    .chooseCity img {
        left: 100%;
        top: 3%;
        width: 24px;
        height: 24px;
        margin-left: -32px;
    }

    .province {
        margin: 0;
    }

    .province ul {
        padding: 0 0 0 24px;
    }

    .province ul li {
        float: left;
        margin-top: 12px;
        width: 70px;
        text-align: center;
        height: 18px;
    }

    .province ul li a {
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        color: #444444;
    }

    .pop_del_loca {
        position: absolute;
        left: 50%;
        top: 50%;
        text-align: center;
        margin-top: -130px;
        margin-left: -223px;
        width: 455px;
        height: 261px;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #F2F2F2;
    }

    .pop_del_loca img {
        position: absolute;
        left: 100%;
        width: 32px;
        height: 32px;
        margin-left: -32px;
        margin-top: -36px;
    }

    .pop_del_loca p {
        font-size: 15px;
        color: #444444;
        width: 100%;
        height: 37px;
        line-height: 37px;
        padding-left: 12px;
    }

    .del_pop_title {
        background-color: #f7f7f7;
    }

    .del_pop_content {
        margin-top: 72px;
        text-align: center;
        background-color: #ffffff;;
    }

    .pop_del_loca button {
        width: 88px;
        height: 28px;
        border-radius: 6px;
        margin-top: 57px;
        color: white;
        cursor: pointer;
    }

    .btn_ok {
        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-right: 32px;
        margin-left: 28px;
    }

    .btn_no {
        background-color: #999999;
        border: 1px solid #999999;
    }

    .zZindex {
        z-index: 3000 !important;
    }
</style>
